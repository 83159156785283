import { useAppContext } from '../context/appContext';
import { HiChevronDoubleLeft, HiChevronDoubleRight } from 'react-icons/hi';
import Wrapper from '../assets/wrappers/PageBtnContainer';
const PageBtnContainer = () => {
	const { numOfPages, page, changePage } = useAppContext();

	const pages = Array.from({ length: numOfPages }, (_, index) => {
		return index + 1;
	});

	const nextPage = () => {
		let newPage = page + 1;
		if (newPage > numOfPages) {
			newPage = 1;
		}
		changePage(newPage);
	};

	const previousPage = () => {
		let newPage = page - 1;
		if (newPage < 1) {
			newPage = numOfPages;
		}
		changePage(newPage);
	};

	return (
		<Wrapper>
			<button className="prev-btn" onClick={previousPage}>
				<HiChevronDoubleLeft />
				Prev
			</button>
			<div className="btn-container">
				{pages.map((pageNumber) => {
					return (
						<button
							type="button"
							className={pageNumber === page ? 'pageBtn active' : 'pageBtn'}
							key={pageNumber}
							onClick={() => changePage(pageNumber)}>
							{pageNumber}
						</button>
					);
				})}
			</div>
			<button className="next-btn" onClick={nextPage}>
				Next
				<HiChevronDoubleRight />
			</button>
		</Wrapper>
	);
};

export default PageBtnContainer;
